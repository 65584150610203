window.angular.module('MyHippoProducer.Components').directive('earnedRewardCard', function () {
    return {
        restrict: 'E',
        scope: {
            completeDate: '<',
            amount: '<',
            title: '<',
        },
        templateUrl: 'components/earned-reward-card/earned-reward-card.html',
    };
});
